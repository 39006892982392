const blockHero = async($block) => {
	const { gsap } = await import( 'gsap' );
	const { ScrollTrigger } = await import( 'gsap/ScrollTrigger' );
	gsap.registerPlugin( ScrollTrigger );

	function scrollingBadge( $badge ) {
		const $label = $badge.find( '.scrolling-badge__label' );
		let scrollTriggerInstance;

		function adjustBadgePosition() {
			const sectionHeroH = $block.outerHeight();
			const winW = $( window ).width();
			const winH = $( '.scrolling-badge-gap' ).outerHeight();
			$badge.css( 'top', '' );
			$badge.find( '.scrolling-badge__label' ).css( 'opacity', 1 );
			if ( sectionHeroH < winH ) {
				const badgeHeight = $badge.outerHeight();
				let topPosition = sectionHeroH - badgeHeight;
				if (winW < 767) {
					topPosition -= 20;
				} else if (winW < 1440) {
					topPosition -= 30;
				} else {
					topPosition -= 60;
				}
				$badge.css('top', `${topPosition}px`);
			} else {
				$badge.css( 'top', '' );
			}
		}

		function calculateDistance() {
			const badgeTop = $badge.offset().top;
			const winH = $( window ).height();
			return Math.round( winH - badgeTop + 10 );
		}

		function initScrollTrigger() {
			const distance = calculateDistance();

			if ( scrollTriggerInstance ) {
				scrollTriggerInstance.kill();
			}

			const sectionHeroH = $block.outerHeight();
			const winW = $( window ).width();
			const winH = $( '.scrolling-badge-gap' ).outerHeight();
			const badgeH = $badge.outerHeight();
			let toTopPosition;
			if ( sectionHeroH < winH ) {
				if ( winW < 767 ) {
					toTopPosition = winH - badgeH;
				} else if ( winW < 1440 ) {
					toTopPosition = winH - badgeH;
				} else {
					toTopPosition = winH - badgeH - 60;
				}
			} else {
				toTopPosition = false;
			}

			scrollTriggerInstance = gsap.timeline( {
				scrollTrigger: {
					scroller: '.wp-site-blocks',
					trigger: $badge,
					start: `+=${distance} bottom`,
					end: '+=300',
					scrub: true,
					markers: false,
					onUpdate: ( self ) => {
						if ( self.progress === 1 ) {
							$label.css( 'pointer-events', 'none' );
						} else {
							$label.css( 'pointer-events', 'auto' );
						}
					}
				}
			} )
				.to( $badge, {
					scale: 0.7,
					ease: 'none',
					top: toTopPosition ? Math.round( toTopPosition ) : null
				} )
				.to( $label, {
					opacity: 0,
					ease: 'none'
				}, 0 );
		}

		function debounce( func, delay ) {
			let timeout;
			return function() {
				const context = this;
				const args = arguments;
				clearTimeout( timeout );
				timeout = setTimeout(() => func.apply( context, args ), delay );
			};
		}

		adjustBadgePosition();
		initScrollTrigger();

		$( '.wp-site-blocks' ).on( 'scroll', debounce(() => {
			const $scroller = $( '.wp-site-blocks' );
			const scrollPosition = $scroller.scrollTop() + $scroller.height();
			const scrollHeight = $( '.wp-site-blocks__inner' ).outerHeight();
			if ( scrollPosition + 5 > scrollHeight ) {
				$badge.addClass( 'hidden' );
			} else {
				$badge.removeClass( 'hidden' );
			}
		}, 50 ) );

		$( window ).on( 'resize', debounce(() => {
			adjustBadgePosition();
			initScrollTrigger();
			ScrollTrigger.refresh();
		}, 300 ));
	}

	if ( $('.wp-site-blocks').length ) {
		gsap.to( $block.find( '.background-img' ), {
			y: '50%',
			ease: 'none',
			scrollTrigger: {
				scroller: '.wp-site-blocks',
				trigger: $block,
				start: 'top top',
				end: 'bottom top',
				scrub: true
			}
		} );

		const $badge = $( '.scrolling-badge' );
		if ( $badge.length ) {
			scrollingBadge( $badge );
		}
	}
};

themeUtils.loadBlock( blockHero, 'hero', '.section-hero' );
